import $ from 'jquery';

export const footer = () => {
  const titles = $('.page-footer-menu__title');
  const MOBILE = 640;
  const ACTIVE = 'is-active';

  titles.on('click', function () {
    const title = $(this);
    const windowWidth = $(window).width();

    if (title[0].nodeName === 'A' || windowWidth >= MOBILE) {
      return;
    }

    const panel = title.next();

    if (title.hasClass(ACTIVE)) {
      title.removeClass(ACTIVE);
      panel.slideUp(100);
    } else {
      title.addClass(ACTIVE);
      panel.slideDown(100);
    }
  });
};
